import axios, { AxiosInstance } from 'axios';
import { PdfBlockResponse, PdfBlockType } from './model/getPdfBlockResponse';

const API_URL = process.env.REACT_APP_API_URL;
const PDF_BLOCKS_URL = `${API_URL}/pdf-blocks`;
const PDF_BLOCKS_BY_FILE = (file: string, rest = '') =>
  `${PDF_BLOCKS_URL}/${file}${rest}`;

export async function getPdfBlocksWords(
  file: string,
  from: number,
  to: number,
  type: PdfBlockType | 'ALL',
  axiosInstance: AxiosInstance = axios.create(),
): Promise<PdfBlockResponse[]> {
  const response = await axiosInstance.get(PDF_BLOCKS_BY_FILE(file), {
    params: { from, to, type },
  });
  return response.data;
}
