export enum PdfBlockType {
  WORD = 'WORD',
  LINE = 'LINE',
}

export class BoundingBox {
  height: number;
  width: number;
  left: number;
  top: number;
}

export class PdfBlockResponse {
  _id: string;

  file: string;

  type: PdfBlockType;

  page: number;

  boundingBox: BoundingBox;

  text: string;

  relationships?: string[];
}
